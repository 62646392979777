<template>
    <div>
        <header :class="{develop: VUE_APP_DEVELOP == 'develop'}">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-4 col-xl-2 col-logo">
                        <a :href='"/" + flagSelected + "#dne"' class="logo">
                            <img src="@/assets/images/header/logo-dne-exchange.svg" alt="DNE Exchange" v-if="dataSource == 'exchange'">
                            <img src="@/assets/images/header/logo-dne.svg" alt="DNE Digital New Economy" v-else>
                        </a>
                    </div>

                    <div class="col-8 col-xl-10">
                        <div class="row-master">
                            <div class="col-menu">
                                <MenuEcosystem :pageActive="dataSource" />
                            </div>

                            <div class="col-lang-login">
                                <Login />
                                <SelectLanguage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>


        <!-- VALIDATE ACCOUNT -->
        <div class="validate-account" v-if="$store.state.statusLogin && $store.state.profile.account_status !== 'Verified' && loadedAccount">
            <div class="container-fluid">
                <div class="cont">
                    <p>{{ getMyLanguage("ValidateAccount","message") }}</p>
                    <div class="btn-validade-account" @click="checkAccount()">{{ getMyLanguage("ValidateAccount","cta-action") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import MenuEcosystem from '@/components/MenuEcosystem.vue';
    import SelectLanguage from '@/components/SelectLanguage.vue';
    import Login from '@/components/Login.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        props: {
            dataSource: String
        },

        components: {
            MenuEcosystem,
            SelectLanguage,
            Login
        },

        data (){
            return {
                flagSelected: "en",
                loadedAccount: false,

                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
            }
        },

        mounted(){
            setTimeout(() => {
                this.loadedAccount = true
            }, 3000);
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
            
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            checkAccount(){
                this.newtk = localStorage.getItem('accessToken')
                window.location.href = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space/purchase`
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_header.scss" lang="scss" scoped />