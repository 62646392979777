<template>
    <section class="video">
        <div class="banner">
            <div class="container cont-banner">
                <h1>Digital New Economy</h1>
                <p>{{ getMyLanguage("dne-platform", "home.first-tokens.txt") }}</p>
            </div>

            <div class="footer-banner">
                <div class="cta-phase">
                    <!-- <div v-if="$store.state.phaseCurrent.phase_type == 'Private' || $store.state.phaseCurrent.phase_type == 'Public'">
                        <h2 v-if="$store.state.phaseCurrent.phase_type == 'Private'">{{ getMyLanguage("purchase-token","phase-private.nameGroupPhasePrivate") }}</h2>
                        <h2 v-else>{{ getMyLanguage("purchase-token","phase-private.nameGroupPhasePublic") }}</h2>
                    </div> -->

                    <h2>DNE Token</h2>
                    
                    <p>{{ getMyLanguage("purchase-token", "purchase-token.content.dne-token.emphasis") }}</p>

                    <a :href="`/${flagSelected}/account/register`" class="btn-primary btn-register" v-if="!$store.state.statusLogin">
                        {{ getMyLanguage("login","login.register-now") }}
                    </a>
                    
                    <div class="btn-primary btn-register" @click="lnkPurchase()" v-else>
                        {{ getMyLanguage("dne-platform","home.banner.cta") }}
                    </div>
                    
                    <!-- <a :href="`/${flagSelected}/account/lead`" class="btn-primary btn-register" v-if="$store.state.phaseCurrent.phase_type == 'Private'">
                        {{ getMyLanguage("purchase-token","purchase-token.content.phase.interested") }}
                    </a> -->

                    <!-- <a :href="`/${flagSelected}/private-sale`" class="btn-primary btn-register" v-if="$store.state.phaseCurrent.phase_type == 'Private'">
                        {{ getMyLanguage("purchase-token","purchase-token.content.phase.interested") }}
                    </a>

                    <a :href="`/${flagSelected}/account/register`" class="btn-primary btn-register" v-if="$store.state.phaseCurrent.phase_type != 'Private'">
                        {{ getMyLanguage("login","login.register-now") }}
                    </a> -->
                </div>

                <!-- <div v-if="$store.state.phaseCurrent.phase_type == 'Private' || $store.state.phaseCurrent.phase_type == 'Public'">
                    <h2 v-if="$store.state.phaseCurrent.phase_type == 'Public'">ICO DNE Token</h2>
                    <div class="next-phase" v-if="$store.state.phaseCurrent.phase_type == 'Private'">
                        <Countdown />
                    </div>
                </div> -->

            </div>
        </div>

        <button id="playbutton">play</button>
        <div class="video-dne w-100">
            <video id="dneVideo" class="bg_video" autoplay="autoplay" loop="true" muted defaultmuted playsinline>
                <source src="/home/dne-platform.mp4" type="video/mp4">
                <source src="/home/dne-platform.webm" type="video/webm">
            </video>
        </div>
    </section>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import getMyContentLanguage from "@/services/contentLanguage.js";

    // import Countdown from '@/components/Countdown.vue';

    export default {
        components:{
            // Countdown,
        },

        data (){
            return{
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                flagSelected: "en"
            }
        },

        async mounted (){
            this.videoHome()
        },

        methods: {
            languageSelected(){
                if ( this.$store.state.SelectLanguage.code ){
                    this.flagSelected = this.$store.state.SelectLanguage.code
                }
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
            
            videoHome(){
                const video = document.getElementById("dneVideo");
                // video.playbackRate = 0.75;

                const playButton = document.getElementById("playbutton");

                playButton.addEventListener("click", handlePlayButton, false);
                playVideo();

                async function playVideo() {
                    try {
                        await video.play();
                        playButton.className = "playing";
                    } catch(err) {
                        playButton.className = "";
                    }
                }

                function handlePlayButton() {
                    if (video.paused) {
                        playVideo();
                    } else {
                        video.pause();
                        playButton.className = "";
                    }
                }
            },

            lnkPurchase(){
                this.newtk = localStorage.getItem('accessToken')
                window.location.href = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space/purchase`
            },

            openModalLead(){
                this.modalLead.modal = true

                setTimeout(() => {
                    $(".modal-lead").insertAfter("header")

                    setTimeout(() => {
                        this.modalLead.show = true
                    }, 50);
                }, 50);
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_slider.scss" lang="scss" scoped />